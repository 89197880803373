@import 'global.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 52px 14px 24px;
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;

  @include tablet {
    max-height: calc(100vh - 100px);
    min-height: auto;
    padding: 52px 24px 24px;
  }
}

.control {
  padding: 0;
  gap: 12px;
  align-items: center;
  @include heading-h5;
  color: $color-neutral-gray;

  &__chevron {
    margin: 0;
  }
  &__label {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 24px 0;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__title {
    @include heading-h5;
    color: $color-neutral-gray;

    &__time {
      margin: 24px 0;
    }
  }
  &__subtitle {
    @include short-text-t6;
    color: $color-base-500;
  }
  &__selected {
    @include label-l4;
  }
}

.item {
  border-bottom: 1px solid $color-base-400;
}

.item:last-of-type {
  border-bottom: none;
}

.info {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__description {
    @include paragraph-p5;
    margin-bottom: 16px;
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include desktop-max {
      flex-direction: row;
      align-items: center;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 12px;

    @include desktop-max {
      flex-direction: row;
      gap: 40px;
    }

    p {
      @include short-text-t6;
      text-align: center;
      margin: 0;
    }
  }
}

.address {
  width: 100%;
  ::placeholder {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }
}

.addressWithHI {
  @include desktop-max {
    width: 50%;
    display: flex;
  }
}

.recommended {
  display: flex;
  @include desktop-max {
    width: 50%;
  }
}

.infoIcon {
  cursor: pointer;
  @include desktop-max {
    position: absolute;
    top: -2px;
  }
}

.tooltip {
  padding: 16px;
  border-radius: 8px;
}

.phoneLabelInput {
  display: flex;
  align-items: center;
  gap: 4px;
  @include desktop-max {
    position: relative;
  }
}

.confirm {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: $gap-mobile;
  }
  &__button {
    margin-top: $corner-radius-large;
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-top: 24px;

    @include desktop-max {
      flex-direction: row;
    }
  }
}

.content {
  padding: 0;
  padding-bottom: 24px;
}

.map {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
}

.error {
  @include label-l5;
  color: $color-error-fire;

  &__map {
    margin-top: -16px;
  }
}

.button {
  width: 100%;
  background-color: $color-magenta-100;
  color: $color-base-0;
  border-radius: 12px;
  @include label-l4-m;
  height: 48px;

  @include desktop-max {
    width: 290px;
    min-width: 290px;
  }

  &__submit {
    width: 100%;

    @include desktop-max {
      width: 290px;
    }
  }

  &:hover {
    background-color: $color-magenta-200;
  }
  &:active {
    background-color: $color-magenta-0;
  }

  &__invalid {
    background-color: $color-base-200;
    color: $color-base-500;
    &:hover,
    &:active {
      background-color: $color-base-200;
      color: $color-base-500;
    }
  }
  &[data-disabled] {
    background-color: $color-base-200 !important;
    color: $color-base-500 !important;

    .button__icon {
      path {
        fill: $color-base-500;
      }
    }
  }
}

.modal {
  &__root {
    z-index: 300;
    position: fixed;

    @include tablet {
      position: static;
    }
  }
  &__inner {
    padding: 0;
    width: 100%;
    height: 100dvh;
    border-radius: 0;
  }
  &__content {
    height: 100dvh;
    width: 100%;
    max-height: 100dvh;
    border-radius: 0;

    @include tablet {
      width: 478px;
      min-width: 478px;
      max-width: 478px;
      border-radius: $corner-radius-large;
      max-height: calc(100dvh - 100px);
      height: auto;
    }

    @include desktop-max {
      width: 640px;
      min-width: 640px;
      max-width: 640px;
    }
  }
  &__header {
    display: none;
  }
  &__body {
    padding: 0;
  }
}

.close {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
}

.scroll {
  @include scrollbar;
}

.loading {
  :global(.mantine-Overlay-root) {
    background-color: inherit;
  }
}

.bottomSheet {
  position: absolute;
  z-index: 500;
  bottom: 0;
  left: 0;
  right: 0;
}

.bottomSheetContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  .title {
    @include heading-h4;

    @include tablet {
      @include heading-h2;
    }
  }

  .info {
    @include paragraph-p5;
    color: $color-base-900;

    @include tablet {
      @include paragraph-p4;
    }
  }
}

.button {
  &__root {
    margin-top: 12px;
    height: 48px;
    border-radius: 12px;
    background-color: $color-magenta-100;
    color: $color-base-0;
    @include label-l3-m;
    outline: none;

    :global(.mantine-Button-label) {
      margin-top: 1px;
    }

    &:hover {
      background-color: $color-magenta-100;
    }

    &:active {
      background-color: $color-magenta-100;
    }

    &[data-disabled] {
      background-color: $color-primary-200;
      color: $color-base-500;
    }

    @include desktop-max {
      width: 70%;
      height: 40px;
    }
  }
}

.confirm__checkbox {
  align-items: center;
  color: $color-base-900;
  display: flex;
}

.checkbox {
  :global(.mantine-Checkbox-body) {
    align-items: center;
  }

  :global(.mantine-Checkbox-input) {
    cursor: pointer;
    &:checked {
      background-color: $color-primary-500;
    }
  }

  :global(.mantine-Checkbox-label) {
    cursor: pointer !important;
    @include short-text-t6;
    color: $color-black;
    align-self: center;
  }

  &__error {
    :global(.mantine-Checkbox-label) {
      color: $color-error-fire;
    }
  }
}

.promotionTitle {
  @include label-l5;
  margin-left: 12px;
}

.link {
  color: $color-primary-500;
}
