@import 'global.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-base-100;
  border-radius: 12px;
  padding: 16px;
  gap: $gap-mobile;
}

.numbers {
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  &__title {
    @include label-l4;
    color: $color-base-900;
  }
}

.title {
  color: $color-base-900;
  @include heading-h5;
  &__subtitle {
    @include label-l6;
    color: $color-base-700;
    text-align: end;
  }
}

.price {
  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__current {
    @include heading-h4;
    color: $color-black;
    text-wrap: nowrap;
  }
  &__values {
    display: flex;
    gap: 4px;
    align-items: baseline;
  }
  &__old {
    @include label-l2;
    color: $color-base-700;
    text-decoration: line-through;
  }
}
