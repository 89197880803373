@import 'global.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: $gap-mobile;

  @include tablet {
    padding: 0;
  }
}

.markdown {
  padding: 0;

  p {
    padding: 0;
    margin: 0;
    @include paragraph-p5;
    color: $color-base-900;

    @include desktop-max {
      @include paragraph-p4;
    }
  }
  strong {
    @include heading-h6;
    color: $color-base-900;

    @include desktop-max {
      @include heading-h5;
    }
  }
}

.warning {
  margin: 0;
  padding: 0;
  color: $color-primary-900;
  @include paragraph-p4;
}

.beautifulNumber {
  display: flex;
}

.beautifulNumber > div:first-child {
  display: flex;
  flex-direction: column;
  gap: $corner-small;
}

.beautifulNumberTitle {
  @include label-l4;
}

.beautifulNumberMsisdn {
  @include heading-h5;
}

.beautifulNumberPrice {
  @include heading-h5;
  align-self: flex-end;
}

.beautifulNumber > div:last-child {
  display: flex;
  margin-left: auto;
}

.main {
  &__container {
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: $corner-radius-large;
    background-color: $color-primary-100;
  }
}

.promotion {
  p {
    margin: 0 !important;
  }
}